import "./styles.css";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FileUploader } from "react-drag-drop-files";

export default function App() {
  const [selectedFile, setSelectedFile] = useState();
  const [prnNumber, setPrnNumber] = useState();
  const [address, setAddress] = useState()
  const [registeredOwner, setRegisteredOwner] = useState();
  const [incumbrances, setIncumbrances] = useState([]);
  const [selectedIncumbrances, setSelectedIncumbrances] = useState([]);
  const [incumbrancesString, setIncumbrancesString] = useState();
  const [deedpendingregister, setDeedpendingregister] = useState([]);
  const [
    selectedDeedpendingregister,
    setSelectedDeedpendingregister
  ] = useState([]);
  const [deedpendingregisterString, setDeedpendingregisterString] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectionModelIncumbrances, setSelectionModelIncumbrances] = useState(
    []
  );
  const [owners, setOwners] = useState([])
  const [ownersSelectionModel, setOwnersSelectionModel] = useState([])
  const [lotNumber, setLotNumber] = useState([]);
  const [shareHolding, setShareHolding] = useState();
  const [DMC, setDMC] = useState();
  const [OP, setOP] = useState();
  const [lasttransaction, setLastTransaction] = useState();
  const [lasttransactionString, setLastTransactionString] = useState();
  const [infoDate, setInfoDate] = useState()
  const [selectedIndex, setSelectedIndex] = useState(0)


  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "memorialNum",
      headerName: "MEMORIAL NO",
      width: 200,
      editable: true
    },
    {
      field: "dateofInstrument",
      headerName: "DATE OF INSTRUMENT",
      width: 200,
      editable: true
    },
    {
      field: "dateofRegistration",
      headerName: "DATE OF REGISTRATION",
      //type: 'number',
      width: 200,
      editable: true
    },
    {
      field: "nature",
      headerName: "NATURE",
      //description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 200
    },
    {
      field: "infavourof",
      headerName: "IN FAVOUR OF",
      width: 200,
      editable: true
    },
    {
      field: "consideration",
      headerName: "CONSIDERATION",
      width: 200,
      editable: true
    },
    {
      field: "remarks",
      headerName: "REMARKS",
      width: 400,
      editable: true
    }
  ];

  const columnsOwner: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "nameofowner",
      headerName: "NAME OF OWNER",
      width: 200,
      editable: true
    },
    {
      field: "capacity",
      headerName: "CAPACITY",
      width: 200,
      editable: true
    },
    {
      field: "memorialnumber",
      headerName: "MEMORIAL NO.",
      //type: 'number',
      width: 200,
      editable: true
    },
    {
      field: "dateofinstrument",
      headerName: "DATE OF INSTRUMENT",
      //description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 200
    },
    {
      field: "dateofregistration",
      headerName: "DATE OF REGISTRATION",
      width: 200,
      editable: true
    },
    {
      field: "consideration",
      headerName: "CONSIDERATION",
      width: 200,
      editable: true
    },
    {
      field: "remarks",
      headerName: "REMARKS",
      width: 400,
      editable: true
    }
  ];

  

  const MonthConversionTable = {
    JAN: "01",
    FEB: "02",
    MAR: "03",
    APR: "04",
    MAY: "05",
    JUN: "06",
    JUNE: "06",
    JUL: "07",
    AUG: "08",
    SEP: "09",
    OCT: "10",
    NOV: "11",
    DEC: "12"
  };

  function getAllIndexes(arr, val) {
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
    return indexes;
  }

  function calculateAge(birthday) {
    if (birthday > new Date()){
      var ageDifMs = Date.now() - birthday;
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    } else {
      return "expired"
    }
  }

  function commafy(num) {
    var str = num.toString().split(".");
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    return str.join(".");
  }

  function readSingleFile(evt) {
    //Retrieve the first (and only!) File from the FileList object
    var f = evt.target.files[0];

    if (f) {
      var r = new FileReader();
      
      r.onload = function (e) {
        var contents = e.target.result;
        setSelectedFile(contents)
        const prn = contents
          .split("PROPERTY REFERENCE NUMBER (PRN): ")[1]
          .split("</TD>")[0];
        setPrnNumber(prn);

        
        const address = contents
          .split("ADDRESS:")[1]
          .split("地址:")[0]
          .split('<TD colspan="2">')[1]
          .split("</TD>")[0]
          .split("&#13;").join("")
          .split("&amp;").join("&")
          .split(/<br>|<BR>/).join("\n")
          .trim()
        setAddress(address.split("\n").map(item => replaceConjunction(renameAddress(item))).join(""))
        
        setInfoDate(contents.split("本登記冊列明有關物業截至 ")[1].split("&nbsp;")[0])

        const HtmlTableToJson = require("html-table-to-json");

        const jsonTables = HtmlTableToJson.parse(contents);

        const ownerList = jsonTables.results[2]
          .filter(
            (item) => Object.values(item).includes("備註 REMARKS:") === false
          )
          .filter(
            (item) =>
              Object.values(item).includes("業主資料\nOWNER PARTICULARS") ===
              false
          )
          .filter(
            (item) =>
              Object.values(item).includes("業主姓名NAME OF OWNER") === false
          )
          .filter((item) => Object.values(item).length > 1);
        setOwners(ownerList.map((item, index) => ({
          id: index,
          nameofowner: item[1],
          capacity: item[2],
          memorialnumber: item[3],
          dateofinstrument: item[4],
          dateofregistration: item[5],
          consideration: item[6],
          originalArray: item
        })))
        if (ownerList[ownerList.length - 1][2] === "") {
          var temp_registeredOwner = rename(ownerList[ownerList.length - 1][1]);
          setRegisteredOwner(temp_registeredOwner);
          setOwnersSelectionModel([ownerList.length-1])
          setLastTransaction(ownerList[ownerList.length - 1]);
        } else {
          const reverseArray = ownerList.reverse();
          const lastTransactionIndex = reverseArray.findIndex(
            (item) =>
              item[2].includes("TENANT IN COMMON") |
              item[2].includes("JOINT TENANT")
          );
          //const reverseArray_removeunrelevant = reverseArray.splice(
            //lastTransactionIndex,
            //reverseArray.length + 1
          //);
          
          const reverseArray_removeunrelevant = reverseArray.filter( (item, index) =>
            index >= lastTransactionIndex &&
            index < reverseArray.length + 1
          );
          const lastTransactionLastIndex = reverseArray_removeunrelevant.findIndex(
            (item) => item[3] !== ""
          );
          const relevantList = reverseArray_removeunrelevant
            .splice(0, lastTransactionLastIndex + 1)
            .reverse();
          setLastTransaction(relevantList[0]);
          
          ownerList.reverse()
          setOwnersSelectionModel(relevantList.map(itemx => ownerList.findIndex(item => item == itemx)))

          const multiple_owners_namelist = relevantList.map((item) =>
            rename(item[1]).replaceAll("\n", "")
          );
          
          setRegisteredOwner(
            [
              multiple_owners_namelist.pop(),
              multiple_owners_namelist.join(", ")
            ]
              .reverse()
              .join(" and ") + " (" + rename(relevantList[0][2]).replace("\n","") + ")"
          );
        }
        const origin_incumbrancesList = jsonTables.results
          .filter((item) => item.length > 0)
          .filter((item) =>
            Object.values(item[0]).includes("物業涉及的轇轕\nINCUMBRANCES")
          )[0];

        var temp_incumbrancesList = origin_incumbrancesList
          .filter(
            (item) =>
              Object.values(item).includes("註冊摘要編號MEMORIAL NO.") === false
          )
          .filter(
            (item) =>
              Object.values(item).includes("物業涉及的轇轕\nINCUMBRANCES") ===
              false
          );

        for (var i = 0; i < temp_incumbrancesList.length; i++) {
          if (
            Object.values(temp_incumbrancesList[i]).includes("備註 REMARKS:")
          ) {
            //console.log(temp_incumbrancesList[i])
            const databeforeindex = temp_incumbrancesList
              .slice(0, i)
              .filter((item) => Object.values(item).length > 2);
            const lastindexwithdata = temp_incumbrancesList.findIndex(
              (item) => item == databeforeindex[databeforeindex.length - 1]
            );
            //console.log(lastindexwithdata);
            temp_incumbrancesList[lastindexwithdata][7] = temp_incumbrancesList[
              i
            ][1].replace("備註 REMARKS:", "");
          }
        }

        temp_incumbrancesList = temp_incumbrancesList
          .filter((item) => Object.values(item).length > 1)
          .filter(
            (item) => Object.values(item).includes("備註 REMARKS:") === false
          );

        temp_incumbrancesList = temp_incumbrancesList
          .filter((item) => Object.values(item).length > 2)
          .filter(
            (item) => Object.values(item).includes("備註 REMARKS:") === false
          );

        const incumbrancesList = temp_incumbrancesList.map((item, index) => ({
          id: index,
          memorialNum: item[1],
          dateofInstrument: item[2],
          dateofRegistration: item[3],
          nature: item[4],
          infavourof:
            typeof item[5] == "array" && item[5][item[5].length - 1] === "-"
              ? item[5].substring(0, item[5].length - 1)
              : item[5],
          consideration: item[6],
          remarks: item[7] !== null ? item[7] : null
        }));
        //console.log(incumbrancesList);
        setIncumbrances(incumbrancesList);

        const discharge_incumbrances = incumbrancesList.filter(
          (item) =>
            item.nature.includes("DISCHARGE") |
            item.nature.includes("RELEASE") |
            item.nature.includes("REASSIGNMENT")
        );
        if (discharge_incumbrances.length >= 1) {
          var lastdischargeIndex =
            discharge_incumbrances[discharge_incumbrances.length - 1].id;
        } else {
          var lastdischargeIndex = 0;
        }
        //console.log(lastdischargeIndex);
        var mortgage_incumbrances = incumbrancesList.filter(
          (item) =>
            item.id > lastdischargeIndex &&
            item.nature.includes("LEGAL CHARGE") |
              item.nature.includes("MORTGAGE") |
              item.nature.includes("RENTAL ASSIGNMENT") |
              item.nature.includes("ASSIGNMENT OF RENTALS")
        );

        {/* 
        re. land search extractor i randomly selected this one, 
        fyi equitable mortgage is a mortgage for a property which is 
        not yet completed, normally before Certificate of compliance is given, 
        in the case of this land search, although there is no release, 
        only the CC has been given the equitable mortgage is converted into a mortgage. 
        Perhaps it could have an if else statement where if there is a mortgage, 
        cc or occupation permit after equitable mortgage then ignore equitable mortgage. 
        else keep equitable mortgage
        */}
        if((incumbrancesList.filter(item => (item.nature.includes("CERTIFICATE OF COMPLIANCE")|item.nature.includes("OCCUPATION PERMIT")))).length>0){
          mortgage_incumbrances = mortgage_incumbrances.filter(item => item.nature.includes("EQUITABLE")==false)
        }

        
        setSelectedIncumbrances(mortgage_incumbrances);
        setSelectionModelIncumbrances(
          mortgage_incumbrances.map((item) => item.id)
        );

        const dmc_incumbrances = incumbrancesList.filter((item) =>
          (item.nature.includes("DEED OF MUTUAL COVENANT") |
          item.nature.includes("DEED OF COVENANT"))
        );
        if (dmc_incumbrances.length >= 1) {
          setDMC(dmc_incumbrances.filter(item => typeof item.remarks == "string" ? item.remarks.includes("RE-REGISTERED") == false : true));
        }

        const op_incumbrances = incumbrancesList.filter((item) =>
          item.nature.includes("OCCUPATION PERMIT")
        );
        if (op_incumbrances.length == 1) {
          setOP(op_incumbrances[0]);
        } else if (op_incumbrances.length > 1) {
          const OPwithNumber = op_incumbrances.filter((item) =>
            item.nature.includes("NO")
          );
          if (OPwithNumber.length >= 1) {
            setOP(OPwithNumber[0]);
          } else {
            setOP(op_incumbrances[0]);
          }
        }

        var temp_deedpendingregisterList = jsonTables.results
          .filter((item) => item.length > 0)
          .filter((item) =>
            Object.values(item[0]).includes(
              "等待註冊的契約\nDEEDS PENDING REGISTRATION"
            )
          )[0]
          .filter(
            (item) =>
              Object.values(item).includes(
                "*************************************** 登記冊末端 END OF REGISTER ***************************************"
              ) === false
          )
          .filter(
            (item) =>
              Object.values(item).includes(
                "等待註冊的契約\nDEEDS PENDING REGISTRATION"
              ) === false
          )
          .filter(
            (item) =>
              Object.values(item).includes("註冊摘要編號MEMORIAL NO.") === false
          )
          .filter(
            (item) =>
              Object.values(item).includes("********** 無 NIL **********") ===
              false
          )
          .filter((item) => Object.values(item)[0] !== "");

        //console.log(temp_deedpendingregisterList)

        for (var i = 0; i < temp_deedpendingregisterList.length; i++) {
          if (
            (Object.values(temp_deedpendingregisterList[i]).length == 1) |
            Object.values(temp_deedpendingregisterList[i]).includes(
              "備註 REMARKS:"
            )
          ) {
            const stringNotIncludeRemarks = Object.values(
              temp_deedpendingregisterList[i]
            ).filter((item) => item.includes("備註 REMARKS:") == false)[0];
            temp_deedpendingregisterList[i - 1][7] =
              temp_deedpendingregisterList[i][1];
            //stringNotIncludeRemarks
          }
        }

        temp_deedpendingregisterList = temp_deedpendingregisterList.filter(
          (item) =>
            Object.values(item).length > 1 &&
            Object.values(item).includes("備註 REMARKS:") == false
        );

        const deedpendingregisterList = temp_deedpendingregisterList.map(
          (item, index) => ({
            id: index,
            memorialNum: item[1],
            dateofInstrument: item[2],
            dateofRegistration: item[3],
            nature: item[4],
            infavourof:
              typeof item[5] === "array"
                ? item[5][item[5].length - 1] === "-"
                  ? item[5].substring(0, item[5].length - 1)
                  : item[5]
                : "-",
            consideration: item[6],
            remarks: item[7] !== null ? item[7] : null
          })
        );
        setDeedpendingregister(deedpendingregisterList);
        setSelectedDeedpendingregister(deedpendingregisterList);
        setSelectionModel(
          Array.from(Array(deedpendingregisterList.length).keys())
        );

        const lotNumStartingIndex = getAllIndexes(
          jsonTables.results[1].map((item) => item[1]),
          "地段編號"
        );
        const temp_lotNumber = [];
        for (var i = 0; i < lotNumStartingIndex.length; i++) {
          const spiltArray = jsonTables.results[1][
            lotNumStartingIndex[i] + 3
          ][2].split("/");
          const month = spiltArray[1];
          spiltArray[1] = spiltArray[0];
          spiltArray[0] = month;
          const dateforparsing = spiltArray.join("/");

          const year = jsonTables.results[1][
            lotNumStartingIndex[i] + 3
          ][2].split("/")[2];

          if (
            jsonTables.results[1][lotNumStartingIndex[i] + 2][2].includes("TO")
          ) {
            var temp_endDate = jsonTables.results[1][
              lotNumStartingIndex[i] + 2
            ][2]
              .split("TO")[1]
              .trim();
          } else if (
            jsonTables.results[1][lotNumStartingIndex[i] + 2][2].includes(
              "UNTIL"
            )
          ) {
            var temp_endDate = jsonTables.results[1][
              lotNumStartingIndex[i] + 2
            ][2]
              .split("UNTIL")[1]
              .trim();
          } else {
            if (jsonTables.results[1][lotNumStartingIndex[i] + 2][2].includes("RENEWABLE")){
              var origin_and_renewable_lease_term = jsonTables.results[1][lotNumStartingIndex[i] + 2][2].split(" ").filter(item => isNaN(parseFloat(item)) == false).map(item => parseFloat(item)).reduce((partialSum, a) => partialSum + a, 0)
              var renewableTerms = jsonTables.results[1][lotNumStartingIndex[i] + 2][2].split(" ").filter(item => isNaN(parseFloat(item)) == false).map(item => parseFloat(item))[1]
              var temp_endyear = (
                parseInt(year) +
                origin_and_renewable_lease_term
              ).toString();
            
            } else {
              var temp_endyear = (
                parseInt(year) +
                parseInt(jsonTables.results[1][lotNumStartingIndex[i] + 2][2])
              ).toString();
            }
            var temp_endDate =
              jsonTables.results[1][lotNumStartingIndex[i] + 3][2].split(
                "/"
              )[0] +
              "/" +
              jsonTables.results[1][lotNumStartingIndex[i] + 3][2].split(
                "/"
              )[1] +
              "/" +
              temp_endyear;
          }

          if (
            temp_endDate != null &&
            temp_endDate.includes("-") | temp_endDate.includes(" ")
          ) {
            temp_endDate = temp_endDate
              .replaceAll("-", "/")
              .replaceAll(" ", "/");
          }

          var temp_endDate_day = temp_endDate.split("/")[0];
          if (temp_endDate_day.length < 2) {
            temp_endDate_day = "0" + temp_endDate_day;
          }
          var temp_endDate_month = temp_endDate.split("/")[1];
          if (isNaN(parseInt(temp_endDate_month))) {
            temp_endDate_month = MonthConversionTable[temp_endDate_month];
          }
          if (temp_endDate_month.length < 2) {
            temp_endDate_month = "0" + temp_endDate_month;
          }
          var temp_endDate_year = temp_endDate.split("/")[2];

          const temp_endDate_forreamininglease = new Date(
            temp_endDate_month +
              "/" +
              temp_endDate_day +
              "/" +
              temp_endDate_year
          );
          const LotInfo = {
            heldUnder: jsonTables.results[1][lotNumStartingIndex[i] + 1][4],
            lotNumber: jsonTables.results[1][lotNumStartingIndex[i] + 1][2],
            leaseTerms: parseInt(jsonTables.results[1][lotNumStartingIndex[i] + 2][2]),
            commencementDate:
              jsonTables.results[1][lotNumStartingIndex[i] + 3][2],
            remainingTerms: calculateAge(temp_endDate_forreamininglease),
            renewableTerms: renewableTerms,
            endDate:
              temp_endDate_day +
              "/" +
              temp_endDate_month +
              "/" +
              temp_endDate_year
          };
          temp_lotNumber.push(LotInfo);
        }
        setLotNumber(temp_lotNumber);

        var temp_shareholding = jsonTables.results[1]
          .filter((item) => item[1].includes("所佔地段份數"))[0][1]
          .replace("所佔地段份數", "")
          .replace("SHARE OF THE LOT:", "")
          .trim(); //+ " equal and undivided shares";
        temp_shareholding =
          commafy(temp_shareholding.split("/")[0]) +
          " / " +
          commafy(temp_shareholding.split("/")[1]) +
          " equal and undivided shares";
        setShareHolding(temp_shareholding);
      };
      r.readAsText(f);
    } else {
      alert("Failed to load file");
    }
  }

  function allLowerExceptFirst(text) {
    if (typeof text === "string") {
      var newtext = text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
        .split("(")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join("(");
      if (newtext.includes("No. ")) {
        newtext =
          newtext.split("No. ")[0] +
          "No. " +
          newtext.split("No. ")[1].toUpperCase();
      }
      return newtext;
    }
    return "-";
  }

  
  function allLowerExceptFirstAddress(text) {
    if (typeof text === "string") {
      var newtext = text
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
        .split("(")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join("(");
      return newtext;
    }
    return "-";
  }

  function rename(item) {
    if ((item === "-") | (item === "--")) {
      var infavourofstring = "";
    } else if (item != null) {
      var infavourofstring = allLowerExceptFirst(
        item.replace(/[^\x00-\x7F]/g, "")
      )
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/\s+/g, " ")
        .trim();
      if (infavourofstring.slice(-1) === "-") {
        infavourofstring = infavourofstring.slice(0, -1);
      }
      infavourofstring = infavourofstring
        .replaceAll("()", "")
        .replaceAll("(hk)", "(HK)")
        .replaceAll("(hong Kong)", "(Hong Kong)")
        .trim();
      infavourofstring = infavourofstring + "\n";
    }
    return infavourofstring;
  }

  function renameAddress(item) {
    if ((item === "-") | (item === "--")) {
      var infavourofstring = "";
    } else if (item != null) {
      var infavourofstring = allLowerExceptFirstAddress(
        item.replace(/[^\x00-\x7F]/g, "")
      )
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/\s+/g, " ")
        .trim();
      if (infavourofstring.slice(-1) === "-") {
        infavourofstring = infavourofstring.slice(0, -1);
      }
      infavourofstring = infavourofstring
        .replaceAll("()", "")
        .replaceAll("(hk)", "(HK)")
        .replaceAll("(hong Kong)", "(Hong Kong)")
        .trim();
      infavourofstring = infavourofstring + "\n";
    }
    return infavourofstring;
  }
  

  function genReportIncumbrancesString(item) {
    if ((item.infavourof === "-") | (item.infavourof === "--")) {
      var infavourofstring = "";
    } else {
      var infavourofstring =
        "In favour of " +
        allLowerExceptFirst(item.infavourof.replace(/[^\x00-\x7F]/g, ""))
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/\s+/g, " ")
          .trim();
      if (infavourofstring.slice(-1) === "-") {
        infavourofstring = infavourofstring.slice(0, -1);
      }
      infavourofstring = infavourofstring.replace("()","").replace("Citic","CITIC").replace("Dbs", "DBS").replace("Ocbc", "OCBC") + "\n";
    }

    if (typeof item.consideration === "string") {
      if (item.consideration == "-") {
        var considerationstring = "";
      } else if (item.consideration == "ALL MONEY") {
        var considerationstring = "For a consideration of all money" + "\n";
      } else if (item.consideration == "ALL MONEYS") {
        var considerationstring = "For a consideration of all moneys" + "\n";
      } else if (item.consideration == "ALL MONIES") {
        var considerationstring = "For a consideration of all monies" + "\n";
      } else if (item.consideration == "ALL MONEYS (PT.)") {
        var considerationstring = "For a consideration of all moneys (PT.)" + "\n";
      } else if (item.consideration == "ALL MONIES (PT.)") {
        var considerationstring = "For a consideration of all monies (PT.)" + "\n";
      } else if (item.consideration.includes("TO SECURE")) {
        var considerationstring =
          item.consideration.toLowerCase().slice(0, 1).toUpperCase() +
          item.consideration.toLowerCase().slice(1) +
          "\n";
      } else if (item.remarks == "THE CONSIDERATION IS ALL MONEYS") {
        var considerationstring = "For a consideration of all moneys" + "\n";
      } else if (item.consideration[0] == "$") {
        var considerationstring =
          "For a consideration of HK" +
          item.consideration.split(".")[0].replaceAll("(PT.)", "").trim() +
          "\n";
      } else if (item.consideration !== "") {
        var considerationstring =
          item.consideration.toLowerCase().slice(0, 1).toUpperCase() +
          item.consideration.toLowerCase().slice(1) +
          "\n";
      } else {
        var considerationstring = "";
      }
    }

    considerationstring = considerationstring.replaceAll("(pt.)","(PT.)")

    if (item.remarks) {
      var remarksstring =
        "Remark: " + 
        allLowerExceptFirst(
          item.remarks.replace(/[^\x00-\x7F]/g, "").trim()
        ).replace("( ", "(") + "\n";
    } else {
      var remarksstring = "";
    }

    const string =
      "Memorial No." +
      item.memorialNum +
      "\n" +
      "Dated " +
      item.dateofInstrument +
      "\n" +
      replaceConjunction(allLowerExceptFirst(item.nature)) +
      "\n" +
      replaceConjunction(infavourofstring) +
      replaceConjunction(considerationstring) +
      replaceConjunction(remarksstring);

    return string;
  }

  function replaceConjunction(string){
    return string
    .replaceAll(" With ", " with ")
    .replaceAll(" And ", " and ")
    .replaceAll(" For ", " for ")
    .replaceAll(" Of ", " of ")
    .replaceAll(" On ", " on ")
    .replaceAll(" To ", " to ")
  }


  useEffect(() => {
    const temp_string_array = selectedIncumbrances.map((item) =>
      genReportIncumbrancesString(item)
    );
    setIncumbrancesString(temp_string_array.join("\n"));
  }, [selectedIncumbrances]);

  useEffect(() => {
    const temp_string_array = selectedDeedpendingregister.map((item) =>
      genReportIncumbrancesString(item)
    );
    setDeedpendingregisterString(temp_string_array.join("\n"));
  }, [selectedDeedpendingregister, selectionModel]);

  useEffect(() => {
    document.title = "Land Search Extractor"
  }, []);

  useEffect(() => {
    if (lasttransaction){
      setLastTransactionString(
        "Memorial No. " +
        lasttransaction[3].replace("ASSIGNMENT", "").replace("WITH PLAN", "") +
        //lasttransaction[3].replace(/\D/g, "") +
        "\nDated " +
        lasttransaction[4] +
        "\nAssignment\n" +
        "For a consideration of HK" +
        lasttransaction[6].split(".")[0] + (lasttransaction[6].includes("PT") ? " (PT.)" : "")
      )
    }
  },[lasttransaction])

  return (
    <div className="App">
      <div className="outerDiv">

      <h1>Land Search Extractor</h1>

      <div style={{marginBottom: 20}}>
        <input type="file" onChange={readSingleFile} />
        {/*<FileUploader handleChange={readSingleFile} name="file" types={["html"]} />*/}
      </div>

      <ButtonGroup variant="text" aria-label="text button group">
        <Button 
          sx={{width: "50vw", backgroundColor: selectedIndex==0 ? "#1976d5" : "white", color: selectedIndex==0 ? "white" : "#1976d5"}}
          onClick={() => setSelectedIndex(0)}
        >
          Land search extractor
        </Button>
        <Button 
          sx={{width: "50vw", backgroundColor: selectedIndex==1 ? "#1976d5" : "white", color: selectedIndex==1 ? "white" : "#1976d5"}}
          onClick={() => setSelectedIndex(1)}
        >
          View original file
        </Button>
      </ButtonGroup>

      {/*
      <div style={{marginBottom: 20}}>
        <Button variant="text" onClick={() => {setViewFile(true)}}>View Original Land Search</Button>
      </div>
      */}
      {selectedIndex == 0 &&
      <div>
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{ fontWeight: "bold", height: 25 }}
              >
                Land Registry Data
              </TableCell>
              <TableCell width={10}></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={"Info Date"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Land Search Date</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell align="left" style={{ whiteSpace: "pre-line" }}>
                {infoDate}
              </TableCell>
            </TableRow>
            <TableRow />

            <TableRow
              key={"Address"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell align="left" style={{ whiteSpace: "pre-line" }}>
                {address}
              </TableCell>
            </TableRow>
            <TableRow />

            <TableRow
              key={"PRN Number"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">PRN Number</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell align="left" style={{ whiteSpace: "pre-line" }}>
                {prnNumber}
              </TableCell>
            </TableRow>
            <TableRow />

            <TableRow
              key={"Lot Number"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Lot Number</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell align="left" style={{ whiteSpace: "pre-line" }}>
                {lotNumber
                  .map((item) =>
                    allLowerExceptFirst(item.lotNumber)
                      .replaceAll("Of", "of")
                      .replaceAll("And", "and")
                      .replaceAll(
                        "AND THE EXTENSION THERETO",
                        "and The Extension Thereto"
                      )
                  )
                  .join("\n")}
              </TableCell>
            </TableRow>
            <TableRow />

            <TableRow
              key={"Shareholding"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Shareholding</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell align="left" height={25}>
                {shareHolding}
              </TableCell>
            </TableRow>
            <TableRow />

            <TableRow
              key={"Lease User and Restrictions"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Lease User and Restrictions</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell align="left" style={{ whiteSpace: "pre-line" }}>
                {lotNumber
                  .map(
                    (item) =>
                      "Lease for " + item.leaseTerms + " years commencing from " +
                      item.commencementDate +

                      (item.renewableTerms!=null ? " and is renewable for a further term of " + item.renewableTerms + " years" : "") +

                      //" until " +
                      //item.endDate +
                      " held under " +
                      rename(item.heldUnder.split(".")[0])
                        .replaceAll("Of", "of")
                        .replaceAll("\n", "") + 
                      (item.heldUnder.split(".")[1]!=null ?
                      "." +
                      item.heldUnder.split(".")[1] : "") +
                      (item.remainingTerms!="expired" ?
                      "\n(remaining lease term: " +
                      item.remainingTerms +
                      " years)"
                      :
                      "")
                  )
                  .join("\n")}
              </TableCell>
            </TableRow>
            <TableRow />

            <TableRow
              key={"Government Rent"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Government Rent</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {lotNumber[0] && lotNumber[0].remainingTerms=="expired" && "3% rateable value of the property"}
              </TableCell>
            </TableRow>
            <TableRow />

            <TableRow
              key={"Deed of Mutual Covenant"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Deed of Mutual Covenant</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {DMC &&
                  DMC.map(item => {
                    return(
                  "Memorial No. " +
                    item.memorialNum +
                    "\n" +
                    "Registered on " +
                    item.dateofRegistration
                  )}).join("\n\n")
                }
              </TableCell>
            </TableRow>
            <TableRow />
            <TableRow
              key={"Occupation Permit"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell style={{ whiteSpace: "pre-line" }}>
                Occupation Permit
                {OP &&
                  OP.nature.includes("NO") &&
                  "\n" + "No" + OP.nature.split("NO")[1]}
              </TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {OP &&
                  "Memorial No. " +
                    OP.memorialNum +
                    "\n" +
                    "Dated " +
                    OP.dateofInstrument}
              </TableCell>
            </TableRow>
            <TableRow />
            <TableRow
              key={"Registered Owner"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Registered Owner</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {registeredOwner}
              </TableCell>
            </TableRow>
            <TableRow />
            <TableRow
              key={"Last Transaction"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Last Transaction</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {lasttransaction &&
                  lasttransactionString}
              </TableCell>
            </TableRow>
            <TableRow />
            <TableRow
              key={"Registered Encumbrances"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Registered Encumbrances</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {incumbrancesString}
              </TableCell>
            </TableRow>
            <TableRow />
            <TableRow
              key={"Deeds Pending Registration"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">Deeds Pending Registration</TableCell>
              <TableCell align="left">:</TableCell>
              <TableCell style={{ whiteSpace: "pre-line" }}>
                {deedpendingregisterString}
              </TableCell>
            </TableRow>
            <TableRow />
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ height: 580, width: "100%", marginBottom: 100 }}>
        <h3>Select incumbrances</h3>
        <DataGrid
          getRowHeight={() => "auto"}
          style={{ textAlign: "left" }}
          rows={incumbrances}
          columns={columns}
          //pageSize={10}
          //rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            setSelectionModelIncumbrances(ids);
            const selectedIDs = Array.from(new Set(ids));
            const selectedRowData = incumbrances.filter((item) =>
              selectedIDs.includes(item.id)
            );
            setSelectedIncumbrances(selectedRowData);
          }}
          selectionModel={selectionModelIncumbrances}
        />
      </div>

      <h3>Output incumbrances</h3>

      <textarea
        type="text"
        id="incumbrancesInputBox"
        defaultValue={incumbrancesString}
        style={{ width: "100%", height: 500, textAlign: "left", fontSize: 11, fontFamily: "inherit" }}
        onChange={(event) => setIncumbrancesString(event.target.value)}
      />

      <div style={{ height: 580, width: "100%", marginBottom: 100 }}>
        <h3>Select deed pending registration</h3>
        <DataGrid
          getRowHeight={() => "auto"}
          style={{ textAlign: "left" }}
          rows={deedpendingregister}
          columns={columns}
          //pageSize={10}
          //rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
            const selectedIDs = Array.from(new Set(ids));
            const selectedRowData = deedpendingregister.filter((item) =>
              selectedIDs.includes(item.id)
            );
            setSelectedDeedpendingregister(selectedRowData);
          }}
          selectionModel={selectionModel}
        />
      </div>

      <h3>Output deed pending registration</h3>

      <textarea
        type="text"
        defaultValue={deedpendingregisterString}
        style={{ width: "100%", height: 500, textAlign: "left", fontSize: 11, fontFamily: "inherit"}}
        onChange={(event) => setDeedpendingregisterString(event.target.value)}
      />

      <div>
        <div style={{ height: 280, width: "100%", marginBottom: 100 }}>
          <h3>Select owners</h3>
          <DataGrid
            getRowHeight={() => "auto"}
            style={{ textAlign: "left" }}
            rows={owners}
            columns={columnsOwner}
            //pageSize={10}
            //rowsPerPageOptions={[10]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => {
              setOwnersSelectionModel(ids);
              const selectedIDs = Array.from(new Set(ids));
              const selectedRowData = owners.filter((item) =>
                selectedIDs.includes(item.id)
              );
              if (selectedRowData.length==1){
                var selectedItem = selectedRowData[0]["originalArray"]
                setLastTransaction(selectedItem);
                setRegisteredOwner(rename(selectedItem[1]))
              }
              else if (selectedRowData.length>1) {
                const selectedRowDataArray = selectedRowData.map(item => item["originalArray"]).filter(item => isNaN(item[6].replaceAll("$","").replaceAll(",",""))==false)
                var capacity = ""
                if (selectedRowDataArray.length>0){
                  setLastTransaction(selectedRowDataArray[0])
                  capacity = selectedRowDataArray[0][2]
                }
                const multiple_owners_namelist = selectedRowData.map(item => rename(item["originalArray"][1]).replaceAll("\n", ""))
                const ownersString = [
                  multiple_owners_namelist.pop(),
                  multiple_owners_namelist.join(", ")
                ]
                  .reverse()
                  .join(" and ")
                + (capacity!="" ? " (" + rename(capacity).replace("\n","") + ")" : "")
                setRegisteredOwner(ownersString)
                
              } else {
                setLastTransaction()
                setRegisteredOwner()
              }
            }}
            selectionModel={ownersSelectionModel}
          />
        </div>

        <h3>Output Registered Owner</h3>

        <textarea
          type="text"
          id="ownersInputBox"
          defaultValue={registeredOwner}
          style={{ width: "100%", height: 100, textAlign: "left", fontSize: 11, fontFamily: "inherit" }}
          onChange={(event) => setRegisteredOwner(event.target.value)}
        />

        <h3>Output Last Transaction</h3>

        <textarea
          type="text"
          id="ownersInputBox"
          defaultValue={lasttransactionString}
          style={{ width: "100%", height: 100, textAlign: "left", fontSize: 11, fontFamily: "inherit" }}
          onChange={(event) => setLastTransactionString(event.target.value)}
        />
        </div>
      </div>
      }
      </div>

      {typeof selectedFile == "string" && selectedIndex==1 &&
      <iframe srcdoc={selectedFile} width="100%" height="700" />
      }
    </div>
  );
}
